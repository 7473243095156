import React, { useState, useEffect } from 'react';
import Button from "../button/button_v2.js";
import * as S from './styles.js';
import logo from "../../assets/logo-verde-fundo-branco.png"
import check from "../../assets/check-verde.png"
import x from "../../assets/X-cinza.png"
import { withRouter } from "react-router-dom"
import api from '../../services/api.js';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min.js';

export const USER = "@userinfo";
export const OPEN = "@open";
export const logged = '@logged';
export const GOOGLE_EMAIL = '@agroreceita_google_email';
export const GOOGLE_USER = '@agroreceita_google_user';
export const GOOGLE_USER_PROFILE = '@agroreceita_google_user_profile';


function PlanTable({ ...props }) {

    const [isMobile, setIsMobile] = useState(false);
    const [positionMiddle, setPositionMiddle] = useState(false);
    const [utmCompra, setUtmCompra] = useState('');
    const [planos, setPlanos] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [permissoes, setPermissoes] = useState([]);
    const [grid, setGrid] = useState([]);
    const [userPlanLevel, setUserPLanLevel] = useState('');
    const [userPlanId, setUserPLanId] = useState('');
    const [showPrepaid, setShowPrepaid] = useState(false);

    const location = useLocation();

    if (localStorage.getItem("lastAttemptedPath")) {
        const lastPath = localStorage.getItem("lastAttemptedPath");

        if (lastPath.includes('utm_source')) {
            let url = new URL(lastPath);

            let queryString = url.search;

            localStorage.setItem("utm_compra", queryString.substring(1))
        }

        localStorage.removeItem("lastAttemptedPath");

        window.location.href = lastPath;
    }

    useEffect(() => {
        // Ler o parâmetro 'prepaid' da URL
        const searchParams = new URLSearchParams(location.search);
        const isPrepaid = searchParams.get('prepaid') === 'true';

        // Ativar o pré-pago se o parâmetro for true
        if (isPrepaid) {
            setShowPrepaid(true);
        }
    }, [location.search]);

    const assinar = (planoId) => {
        localStorage.setItem('@loginReload', 'true')
        const cupom = decodeURIComponent(new URLSearchParams(window.location.search).get('cupom'));
        const utm_term = decodeURIComponent(new URLSearchParams(window.location.search).get('utm_term'));
        const utm_source = decodeURIComponent(new URLSearchParams(window.location.search).get('utm_source'));
        const utm_medium = decodeURIComponent(new URLSearchParams(window.location.search).get('utm_medium'));
        const utm_campaign = decodeURIComponent(new URLSearchParams(window.location.search).get('utm_campaign'));

        if (utm_term === 'null' && utm_source === 'null' && utm_medium === 'null' && utm_campaign === 'null') {
            props.history.push(`/compra?${planoId}`);
        } else {
            if (cupom && cupom !== '' && cupom !== null && cupom !== 'null') {
                if (cupom) {
                    props.history.push(`/compra?${planoId}&utm_term=${utm_term}&umt_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&cupom=${cupom}`);
                } 
            } else {
                props.history.push(`/compra?${planoId}&utm_term=${utm_term}&umt_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`);
            }
        }
    }

            const getPlanos = async () => {
                try {
                    const response = await api.get(`/planos/funcoes`);
                    const data = response.data.data;
                    setPlanos(data.planos);
                    setHeaders(data.funcoes);
                    setPermissoes(data.permissoes);
                    setGrid(data.grid)
                } catch (error) {
                    console.error("Erro ao buscar dados da API:", error);
                }
            };

            useEffect(() => {
                getPlanos();
            }, [])

            useEffect(() => {
                const utm = localStorage.getItem("utm_compra")
                setUtmCompra(utm);

            }, [localStorage.getItem("utm_compra")]);

            const SubscribeBasic = () => {
                if (props.location.pathname === '/receituario-agronomico-2024') {
                    return (
                        <a href='#teste-gratis'><Button value='Teste Grátis' margin='15px' height='36px' width='186px' /></a>
                    )
                } else {
                    return (
                        <Button value='Assinar' margin='15px' height='36px' width='186px' onClick={() => assinar(7)} />
                    )
                }
            }

            const subscribeLight = () => {
                if (props.location.pathname === '/receituario-agronomico-2024') {
                    return (
                        <a href='#teste-gratis'><Button value='Teste Grátis' margin='15px' height='36px' width='186px' /></a>
                    )
                } else {
                    return (
                        <Button value='Assinar' margin='15px' height='36px' width='186px' onClick={() => assinar(4)} />
                    )
                }
            }

            const subscribePro = () => {
                if (props.location.pathname === '/receituario-agronomico-2024') {
                    return (
                        <a href='#teste-gratis'><Button value='Teste Grátis' margin='15px' height='36px' width='186px' /></a>
                    )
                } else {
                    return (
                        <Button value='Assinar' margin='15px' height='36px' width='186px' onClick={() => assinar(5)} />
                    )
                }
            }

            const SubscribeMobile = () => {
                if (props.location.pathname === '/receituario-agronomico-2024') {
                    return (
                        <a href='#teste-gratis'><Button value='Teste Grátis' margin='15px' height='36px' width='186px' /></a>
                    )
                } else {
                    return (
                        <Button value='Assinar' margin='15px' height='36px' width='186px' onClick={() => assinar(7)} />
                    )
                }
            }

            const PriceContainerDesktop = () => {
                return (

                    <S.PriceRow isPrepaid={false}>
                        <S.TableCell>
                            <S.AdditionalText>*Para mais usuários ou receitas, <a onClick={() => window.location = '/simulacaoplano/7?'}>simule aqui</a>. Quanto mais você contratar, maior é o seu desconto!</S.AdditionalText>
                        </S.TableCell>
                        {grid
                            .filter(g => g.plan_id !== 6)
                            .map((g, index) => (
                                <S.TableCell key={index}>
                                    <S.PriceWithButtonContainer>
                                        <S.Price>
                                            <span className="currency-symbol">R$</span>
                                            <span>{g.vlr}</span>
                                            <span className="cents">,{g.cents}*</span>
                                        </S.Price>
                                        <S.PriceDescription>mensais/usuário</S.PriceDescription>
                                        {g.plan_id === 4 || g.plan_id === 5 ? (
                                            <S.PriceDescription>10% OFF NO PLANO ANUAL</S.PriceDescription>
                                        ) : (
                                            <S.PriceDescription><br /></S.PriceDescription>
                                        )}
                                        {g.plan_id === 7 ? (
                                            SubscribeBasic()
                                        ) : g.plan_id === 4 ? (
                                            subscribeLight()
                                        ) : g.plan_id === 5 ? (
                                            subscribePro()
                                        ) : null}
                                    </S.PriceWithButtonContainer>
                                </S.TableCell>
                            ))}
                    </S.PriceRow>

                )
            }

            const PriceContainerMobile = () => {
                return (
                    <S.MobilePriceContainer>
                        {grid
                            .filter(g => g.plan_id !== 6)
                            .map((g, index) => (
                                <S.MobilePriceBox key={index} positionMiddle={index === 1}>
                                    <S.MobilePlans>
                                        {g.plan_id === 7 ? "Plano Basic" :
                                            g.plan_id === 4 ? "Plano Light" :
                                                g.plan_id === 5 ? "Plano Pro" : ""}
                                    </S.MobilePlans>
                                    <S.Price isMobile={true}>
                                        R$ <span>{g.vlr},{g.cents}*</span>
                                    </S.Price>
                                    <S.PriceDescription isMobile={true}>mensais/usuário</S.PriceDescription>
                                    {g.plan_id === 4 || g.plan_id === 5 ? (
                                        <S.PriceDescription>10% OFF NO PLANO ANUAL</S.PriceDescription>
                                    ) : (
                                        <S.PriceDescription><br /></S.PriceDescription>
                                    )}
                                </S.MobilePriceBox>
                            ))}
                    </S.MobilePriceContainer>
                )
            }

            const TablePrepaid = () => {
                // Filtra o plano pré-pago (id 6)
                const prepaidPlan = planos.find(plano => plano.id === 6);
                const prepaidGrid = grid.find(g => g.plan_id === 6);

                if (!prepaidPlan || !prepaidGrid) {
                    return <p>Carregando dados do plano pré-pago...</p>;
                }

                return (
                    <S.MainContainer className='plans'>
                        <S.Title className='plans'>Plano Pré-Pago</S.Title>
                        <S.Subtitle className='plans'>Compre créditos para utilizar quando quiser.</S.Subtitle>

                        <S.ContainerTable isPrepaid={true}>
                            <S.Table>
                                <S.TableHead>
                                    <S.TableRow>
                                        <S.TableHeader><img src={logo} alt='Icone' width="20" className='icon' /></S.TableHeader>
                                        <S.TableHeader>Plano Pré-Pago</S.TableHeader>
                                    </S.TableRow>
                                </S.TableHead>
                                <S.TableBody>
                                    {headers.length > 0 && permissoes.length > 0 ? (
                                        headers.map((header, index) => {
                                            // Encontra a permissão correspondente para o plano pré-pago (id 6)
                                            const permissao = permissoes.find(
                                                per => per.agro_plan_function_id === header.id && per.plan_id === 6
                                            );

                                            // Verifica se é o item "Emissão de Receitas"
                                            if (header.id === 1) { // Substitua 1 pelo ID correto da função "Emissão de Receitas"
                                                return (
                                                    <S.TableRow key={index}>
                                                        <S.TableCell isPrepaid={true}>{header.descricao}</S.TableCell>
                                                        <S.TableCell style={{ fontSize: '14px' }}>
                                                            Escolha o n° de receitas
                                                        </S.TableCell>
                                                    </S.TableRow>
                                                );
                                            } else {
                                                return (
                                                    <S.TableRow key={index}>
                                                        <S.TableCell isPrepaid={true}>{header.descricao}</S.TableCell>
                                                        <S.TableCell>
                                                            {permissao?.status === 1 ? (
                                                                <img isPrepaid={true} src={check} alt="Certo" width="15%" />
                                                            ) : (
                                                                <img isPrepaid={true} src={x} alt="Errado" width="15%" />
                                                            )}
                                                        </S.TableCell>
                                                    </S.TableRow>
                                                );
                                            }
                                        })
                                    ) : (
                                        <p>Carregando funcionalidades...</p>
                                    )}

                                    <S.SupportRow isPrepaid={true}>
                                        <S.TableCell>
                                            <S.SupportTitle isPrepaid={true}>Suporte</S.SupportTitle>
                                        </S.TableCell>
                                        <S.TableCell>
                                            <S.SupportPlanName isPrepaid={true}>Suporte Plano Pré-Pago</S.SupportPlanName>
                                            <S.SupportList>
                                                <S.SupportItem>WhatsApp</S.SupportItem>
                                                <S.SupportItem>E-mail</S.SupportItem>
                                                <S.SupportItem>Central de Ajuda</S.SupportItem>
                                            </S.SupportList>
                                        </S.TableCell>
                                    </S.SupportRow>

                                    <S.PriceRow isPrepaid={true}>
                                        <S.TableCell>
                                            <S.AdditionalText isPrepaid={true}>
                                                *Para mais usuários ou receitas, <a onClick={() => window.location = '/simulacaoplano/7?'}>simule aqui</a>. Quanto mais você contratar, maior é o seu desconto!
                                            </S.AdditionalText>
                                        </S.TableCell>
                                        <S.TableCell>
                                            <S.PriceWithButtonContainer isPrepaid={true}>
                                                <S.Price isPrepaid={true}>
                                                    <span className="currency-symbol">R$</span>
                                                    <span>{prepaidGrid.vlr}</span>
                                                    <span className="cents">,{prepaidGrid.cents}*</span>
                                                </S.Price>
                                                <S.PriceDescription>por Receituário</S.PriceDescription>
                                                <S.PriceDescription>Desconto progressivo</S.PriceDescription>
                                                <Button
                                                    value='Comprar Créditos'
                                                    margin='15px'
                                                    height='36px'
                                                    width='186px'
                                                    onClick={() => assinar(6)}
                                                />
                                            </S.PriceWithButtonContainer>
                                        </S.TableCell>
                                    </S.PriceRow>
                                </S.TableBody>
                            </S.Table>
                            <S.PrePaidPlanContainer>
                                Conheça os <button onClick={() => setShowPrepaid(false)}>Planos de Assinatura</button> e emita seus Receituários Agronômicos com tranquilidade.
                            </S.PrePaidPlanContainer>
                        </S.ContainerTable>
                    </S.MainContainer>
                );
            };


            const isPlans = () => {
                if (showPrepaid) {
                    return (
                        <>
                            {TablePrepaid()}
                        </>
                    )
                }
                if (props.location.pathname === '/planos') {
                    return (
                        <S.MainContainer className='plans'>

                            <S.Title className='plans'>Tome melhores decisões com o AgroReceita</S.Title>
                            <S.Subtitle className='plans'>Ideal para Revendas/Cooperativas agrícolas, Agrônomos, Técnicos agrícolas, Fabricantes de defensivos e Produtores rurais.</S.Subtitle>

                            <S.ContainerTable isPrepaid={false}>
                                <S.Table>
                                    <S.TableHead>
                                        <S.TableRow>
                                            <S.TableHeader><img src={logo} alt='Icone' width="20" className='icon' /></S.TableHeader>
                                            <S.TableHeader>Plano Basic</S.TableHeader>
                                            <S.TableHeader>Plano Light</S.TableHeader>
                                            <S.TableHeader>Plano Pro</S.TableHeader>
                                        </S.TableRow>
                                    </S.TableHead>
                                    <S.TableBody>
                                        {headers.length > 0 && planos.length > 0 && permissoes.length > 0 ? (
                                            headers.map((header, index) => (
                                                <S.TableRow key={index}>
                                                    <S.TableCell isPrepaid={false}>{header.descricao}</S.TableCell>
                                                    {planos
                                                        .filter(plano => plano.id !== 6) // Filtra para remover o plano pré-pago (id 6)
                                                        .map((plano) => {
                                                            // Encontra a permissão correspondente
                                                            const permissao = permissoes.find(
                                                                per => per.agro_plan_function_id === header.id && per.plan_id === plano.id
                                                            );

                                                            // Verifica se é o item "Emissão de Receitas"
                                                            if (header.id === 1) { // Substitua 1 pelo ID correto da função "Emissão de Receitas"
                                                                return (
                                                                    <S.TableCell key={plano.id} isPrepaid={false}>
                                                                        {permissao?.descricao || "Não disponível"}
                                                                    </S.TableCell>
                                                                );
                                                            } else {
                                                                return (
                                                                    <S.TableCell key={plano.id} isPrepaid={false}>
                                                                        {permissao?.status === 1 ? (
                                                                            <img isPrepaid={false} src={check} alt="Certo" width="30" />
                                                                        ) : (
                                                                            <img isPrepaid={false} src={x} alt="Errado" width="30" />
                                                                        )}
                                                                    </S.TableCell>
                                                                );
                                                            }
                                                        })}
                                                </S.TableRow>
                                            ))
                                        ) : (
                                            <></>
                                        )}

                                        <S.SupportRow>
                                            <S.TableCell>
                                                <S.SupportTitle isPrepaid={false}>Suporte</S.SupportTitle>
                                            </S.TableCell>
                                            <S.TableCell>
                                                <S.SupportPlanName>Suporte Plano Basic</S.SupportPlanName>
                                                <S.SupportList>
                                                    <S.SupportItem>WhatsApp</S.SupportItem>
                                                    <S.SupportItem>E-mail</S.SupportItem>
                                                    <S.SupportItem>Central de Ajuda</S.SupportItem>
                                                </S.SupportList>
                                            </S.TableCell>
                                            <S.TableCell>
                                                <S.SupportPlanName>Suporte Plano Light</S.SupportPlanName>
                                                <S.SupportList>
                                                    <S.SupportItem>Telefone</S.SupportItem>
                                                    <S.SupportItem>WhatsApp</S.SupportItem>
                                                    <S.SupportItem>E-mail</S.SupportItem>
                                                    <S.SupportItem>Central de Ajuda</S.SupportItem>
                                                </S.SupportList>
                                            </S.TableCell>
                                            <S.TableCell>
                                                <S.SupportPlanName>Suporte Plano Pro</S.SupportPlanName>
                                                <S.SupportList>
                                                    <S.SupportItem>Suporte Prioritário</S.SupportItem>
                                                    <S.SupportItem>Telefone</S.SupportItem>
                                                    <S.SupportItem>WhatsApp</S.SupportItem>
                                                    <S.SupportItem>E-mail</S.SupportItem>
                                                    <S.SupportItem>Central de Ajuda</S.SupportItem>
                                                </S.SupportList>
                                            </S.TableCell>
                                        </S.SupportRow>

                                        {PriceContainerDesktop()}

                                    </S.TableBody>
                                </S.Table>
                                <S.PrePaidPlanContainer>
                                    Conheça o <button onClick={() => setShowPrepaid(true)}>Plano Pré-Pago</button> e compre créditos para utilizar quando quiser.
                                </S.PrePaidPlanContainer>
                            </S.ContainerTable>


                            {PriceContainerMobile()}

                            <S.ButtonBelowPrices>
                                {SubscribeMobile()}
                            </S.ButtonBelowPrices>

                            <S.MobileAdditionalTextContainer>
                                <S.MobileAdditionalText>
                                    *Para mais usuários ou receitas, <a onClick={() => window.location = '/simulacaoplano/7?'}>simule aqui.</a>. Quanto mais você contratar, maior é o seu desconto!
                                </S.MobileAdditionalText>
                            </S.MobileAdditionalTextContainer>


                        </S.MainContainer>
                    );
                } else {
                    return (
                        <S.MainContainer className='receituario'>

                            <S.Title>Tome melhores decisões com o AgroReceita</S.Title>
                            <S.Subtitle>Ideal para Revendas/Cooperativas agrícolas, Agrônomos, Técnicos agrícolas, Fabricantes de defensivos e Produtores rurais.</S.Subtitle>

                            <S.ContainerTable className='receituario' isPrepaid={false}>
                                <S.Table>
                                    <S.TableHead>
                                        <S.TableRow>
                                            <S.TableHeader><img src={logo} alt='Icone' width="30" className='icon' /></S.TableHeader>
                                            <S.TableHeader>Plano Basic</S.TableHeader>
                                            <S.TableHeader>Plano Light</S.TableHeader>
                                            <S.TableHeader>Plano Pro</S.TableHeader>
                                        </S.TableRow>
                                    </S.TableHead>
                                    <S.TableBody>
                                        {headers.length > 0 && planos.length > 0 && permissoes.length > 0 ? (
                                            headers.map((header, index) => (
                                                <S.TableRow key={index}>
                                                    <S.TableCell isPrepaid={false}>{header.descricao}</S.TableCell>
                                                    {planos
                                                        .filter(plano => plano.id !== 6) // Filtra para remover o plano pré-pago (id 6)
                                                        .map((plano) => {
                                                            // Encontra a permissão correspondente
                                                            const permissao = permissoes.find(
                                                                per => per.agro_plan_function_id === header.id && per.plan_id === plano.id
                                                            );

                                                            // Verifica se é o item "Emissão de Receitas"
                                                            if (header.id === 1) { // Substitua 1 pelo ID correto da função "Emissão de Receitas"
                                                                return (
                                                                    <S.TableCell key={plano.id} isPrepaid={false}>
                                                                        {permissao?.descricao || "Não disponível"}
                                                                    </S.TableCell>
                                                                );
                                                            } else {
                                                                return (
                                                                    <S.TableCell key={plano.id} isPrepaid={false}>
                                                                        {permissao?.status === 1 ? (
                                                                            <img isPrepaid={false} src={check} alt="Certo" width="30" />
                                                                        ) : (
                                                                            <img isPrepaid={false} src={x} alt="Errado" width="30" />
                                                                        )}
                                                                    </S.TableCell>
                                                                );
                                                            }
                                                        })}
                                                </S.TableRow>
                                            ))
                                        ) : (
                                            <p>Carregando dados...</p>
                                        )}

                                        <S.SupportRow>
                                            <S.TableCell>
                                                <S.SupportTitle isPrepaid={false}>Suporte</S.SupportTitle>
                                            </S.TableCell>
                                            <S.TableCell>
                                                <S.SupportPlanName>Suporte Plano Basic</S.SupportPlanName>
                                                <S.SupportList>
                                                    <S.SupportItem>WhatsApp</S.SupportItem>
                                                    <S.SupportItem>E-mail</S.SupportItem>
                                                    <S.SupportItem>Central de Ajuda</S.SupportItem>
                                                </S.SupportList>
                                            </S.TableCell>
                                            <S.TableCell>
                                                <S.SupportPlanName>Suporte Plano Light</S.SupportPlanName>
                                                <S.SupportList>
                                                    <S.SupportItem>Telefone</S.SupportItem>
                                                    <S.SupportItem>WhatsApp</S.SupportItem>
                                                    <S.SupportItem>E-mail</S.SupportItem>
                                                    <S.SupportItem>Central de Ajuda</S.SupportItem>
                                                </S.SupportList>
                                            </S.TableCell>
                                            <S.TableCell>
                                                <S.SupportPlanName>Suporte Plano Pro</S.SupportPlanName>
                                                <S.SupportList>
                                                    <S.SupportItem>Suporte Prioritário</S.SupportItem>
                                                    <S.SupportItem>Telefone</S.SupportItem>
                                                    <S.SupportItem>WhatsApp</S.SupportItem>
                                                    <S.SupportItem>E-mail</S.SupportItem>
                                                    <S.SupportItem>Central de Ajuda</S.SupportItem>
                                                </S.SupportList>
                                            </S.TableCell>
                                        </S.SupportRow>

                                        {PriceContainerDesktop()}

                                    </S.TableBody>
                                </S.Table>
                                <S.PrePaidPlanContainer>
                                    Conheça o <button onClick={() => setShowPrepaid(true)}>Plano Pré-Pago</button> e compre créditos para utilizar quando quiser.
                                </S.PrePaidPlanContainer>
                            </S.ContainerTable>


                            {PriceContainerMobile()}

                            <S.ButtonBelowPrices>
                                {SubscribeMobile()}
                            </S.ButtonBelowPrices>

                            <S.MobileAdditionalTextContainer>
                                <S.MobileAdditionalText>
                                    *Para mais usuários ou receitas, <a onClick={() => window.location = '/simulacaoplano/7?'}>simule aqui.</a> Quanto mais você contratar, maior é o seu desconto!
                                </S.MobileAdditionalText>
                            </S.MobileAdditionalTextContainer>


                        </S.MainContainer>
                    );
                }
            }

            return (
                <>
                    {isPlans()}
                </>
            )

        }

        export default withRouter(PlanTable);