import React from 'react';
import { withRouter } from "react-router-dom";
import {ContainerPlanos} from "./styles";
import PlanTable from '../../components/planTable';
import * as S from './styles'
import Logo from '../../assets/logo-agroreceita-black.svg'
import Footer from '../../components/footer';

const Planos = ({...props}) => {
    return(
       <>
       <S.HeaderPlans>
        <div>
            <img src={Logo} alt="Logo AgroReceita" />
        </div>

        <div class='links'>
            <a href="https://agroreceita.com.br/ajuda/">Ajuda</a>
            <a href="">Planos</a>
            <a href="https://agroreceita.com.br/blog/">Blog</a>
        </div>

        <div class='buttons-plans'>
            <button onClick={() => window.location = '/login'}>Entrar</button>
            <button onClick={() => window.location = '/cadastro'}>Teste Grátis</button>
        </div>

        <div class='mobile-only'>
            <a href="https://agroreceita.com.br/ajuda/">Ajuda</a>
            <a onClick={() => window.location = '/login'}>Entrar</a>
        </div>
       </S.HeaderPlans>

        <ContainerPlanos>
            <PlanTable />
        </ContainerPlanos>

        <Footer />
       </>
    )
}

export default withRouter(Planos);
